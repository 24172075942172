export const OpenStatusTypes = {
  OPEN_24_HOURS: 'OPEN24',
  OPENS_TODAY: 'OPENSTODAY',
  OPENS_NEXT: 'OPENSNEXT',
  CLOSES_TODAY: 'CLOSESTODAY',
  CLOSES_NEXT: 'CLOSESNEXT',
  CLOSED: 'CLOSED'
};

export const OpenStatusStrings = {
  OPEN_24_HOURS: 'Abierto 24 Horas',
  CLOSED: 'Cerrada',
  OPENS_AT: 'Abre a las',
  OPEN_NOW: 'Abierto Ahora',
  CLOSES_AT: 'Cierra a las'
};